import React, {useState} from 'react'
import { BasicModal } from '../../../../components/Shared'
import { Button, Tab } from 'semantic-ui-react'
import "./asesor.scss";
import {AsesorBioequalForm, ListBioequalAsesor} from "../../../../components/Admin/Bioequal/Asesor"

export function AsesorBioequal() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);
  const panes=[
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListBioequalAsesor actividad="nueva" reload={reload} onReload={onReload}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <div className='cotizacion-page'>
        <Button className='cotizacion-page__new' value="nueva" primary onClick={()=> onOpenCloseModal('nueva')}>Nueva Cotización</Button>
        <Tab menu={{secondary: true}} panes={panes}/> 
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title='Crear cotización Bioequal'>
        <AsesorBioequalForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  )
}
