import React from 'react'
import { Form, Container, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, Header, ListItem, List } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorForm.form'
import { Bioequal } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";

const equalController = new Bioequal();

const especialidades = [
    { key: "ANESTESIOLOGIA", text: "ANESTESIOLOGIA", value: "ANESTESIOLOGIA" },
    { key: "ANGEOLOG", text: "ANGEOLOG", value: "ANGEOLOG" },
    { key: "BARIATRA", text: "BARIATRA", value: "BARIATRA" },
    { key: "CARDIOLOGO", text: "CARDIOLOGO", value: "CARDIOLOGO" },
    { key: "CIRUJANOPLASTICO", text: "CIRUJANO PLASTICO", value: "CIRUJANOPLASTICO" },
    { key: "CLINICASPA", text: "CLINICA/SPA", value: "CLINICA/SPA" },
    { key: "DERMACET", text: "DERMA CET", value: "DERMACET" },
    { key: "ENDOCRINOLOGO", text: "ENDOCRINOLOGO", value: "ENDOCRINOLOGO" },
    { key: "EPIDEMIOLOGO", text: "EPIDEMIOLOGO", value: "EPIDEMIOLOGO" },
    { key: "ESTETICAYLONGEVIDAD", text: "ESTETICA Y LONGEVIDAD", value: "ESTETICAYLONGEVIDAD" },
    { key: "ESTOMATOLOGO", text: "ESTOMATOLOGO", value: "ESTOMATOLOGO" },
    { key: "GASTROENTEROLOGO", text: "GASTROENTEROLOGO", value: "GASTROENTEROLOGO" },
    { key: "GERIATRIA", text: "GERIATRIA", value: "GERIATRIA" },
    { key: "GINECOLOGO", text: "GINECOLOGO", value: "GINECOLOGO" },
    { key: "HOMEOPATA", text: "HOMEOPATA", value: "HOMEOPATA" },
    { key: "MEDICINADELDEPORTE", text: "MEDICINA DEL DEPORTE", value: "MEDICINADELDEPORTE" },
    { key: "MEDICINAINTERNA", text: "MEDICINA INTERNA", value: "MEDICINAINTERNA" },
    { key: "MEDICODERMATOLOGO", text: "MEDICO DERMATOLOGO", value: "MEDICODERMATOLOGO" },
    { key: "MEDICOGENERAL", text: "MEDICO GENERAL", value: "MEDICOGENERAL" },
    { key: "MEDICO UROLOGO", text: "MEDICO UROLOGO", value: "MEDICOUROLOGO" },
    { key: "MEDICO VETERINARIO", text: "MEDICO VETERINARIO", value: "MEDICOVETERINARIO" },
    { key: "OFTALMOLOGO", text: "OFTALMOLOGO", value: "OFTALMOLOGO" },
    { key: "OTORINOLARINGOLOGO", text: "OTORINOLARINGOLOGO", value: "OTORINOLARINGOLOGO" },
    { key: "PEDIATRA", text: "PEDIATRA", value: "PEDIATRA" },
    { key: "PODOLOGO", text: "PODOLOGO", value: "PODOLOGO" }
];

const bases = [
    { key: "Crema", text: "Crema", value: "Crema" },
    { key: "Gel", text: "Gel", value: "Gel" }
];

export function AsesorBioequalForm(props) {
    const { onClose, onReload, equal } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;

    const formik = useFormik({
        initialValues: initialValues(equal, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                    cliente: formValue.cliente,
                    asesor: CorreoAsesor,
                    asesornom: nombreCompleto,
                    base: formValue.base,
                    activos: formValue.activos,
                    especialidad: formValue.especialidad,
                    padecimiento: formValue.padecimiento,
                    concentimiento: formValue.concentimiento
                };

                if (!equal) {
                    await equalController.createBioequal(accessToken, data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const fechaActual = new Date().toLocaleDateString('es-ES');
    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                <Form.Group className="form-group">
                    <p>Asesor: <span>{nombreCompleto}</span></p>
                    <p>Fecha: <span>{fechaActual}</span></p>
                </Form.Group>
                <Form.Input label="Médico responsable" name="cliente" placeholder="Nombre del médico responsable" onChange={formik.handleChange} value={formik.values.cliente} error={formik.errors.cliente} />
                <Form.Dropdown label="Nombre especialidad" placeholder="Seleccione una especialidad" name="especialidad" options={especialidades} selection onChange={(_, data) => formik.setFieldValue("especialidad", data.value)} value={formik.values.especialidad} error={formik.errors.especialidad} />
                <Form.Dropdown label="Base" placeholder="Base" options={bases} selection onChange={(_, data) => formik.setFieldValue("base", data.value)} value={formik.values.base} error={formik.errors.base} />
                <Form.TextArea label="Activos" name="activos" placeholder="Ingresa los activos para la fórmula, con su porcentaje" onChange={formik.handleChange} value={formik.values.activos} error={formik.errors.activos} />
                <Form.Input label="Padecimiento" name="padecimiento" placeholder="Padecimiento o intención de uso (p. ej. Ovario poliquístico o aparato/equipo)" onChange={formik.handleChange} value={formik.values.padecimiento} error={formik.errors.padecimiento} />
            </Container>
            <br></br>
            <Container className='form-cotizacion__tercero'>
                <br />
                <Form.Checkbox label='Ver tablas de uso seguro de Hormonas' name='tablas' onChange={(_, data) => formik.setFieldValue("tablas", data.checked)} checked={formik.values.tablas} error={formik.errors.tablas} />
                <br />
            </Container>
            {
            formik.values.tablas && (
            <Container>
                <Header className='center'>Hombres - Hormonas y dosis sugeridas</Header>
                
                 <Table fixed>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Hormona</TableHeaderCell>
                            <TableHeaderCell>Dosis máxima</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>Testosterona</TableCell>
                            <TableCell>200 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Pregnenolona</TableCell>
                            <TableCell>100 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>DHEA</TableCell>
                            <TableCell>200 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Progesterona</TableCell>
                            <TableCell>10 mg/g</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                    <br></br>
                    <Header className='center'>Mujeres - Hormonas y dosis sugeridas</Header>
                    <Table fixed>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Hormona</TableHeaderCell>
                            <TableHeaderCell>Dosis máxima tópica</TableHeaderCell>
                            <TableHeaderCell>Dosis máxima intravaginal</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>Estradiol *</TableCell>
                            <TableCell>2 mg/g</TableCell>
                            <TableCell> 0.5 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Estriol *</TableCell>
                            <TableCell>8 mg/g</TableCell>
                            <TableCell>8 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Progesterona</TableCell>
                            <TableCell>240 mg/g</TableCell>
                            <TableCell>50 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Pregnenolona</TableCell>
                            <TableCell>100 mg/g</TableCell>
                            <TableCell>No se administra</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>DHEA</TableCell>
                            <TableCell>40 mg/g</TableCell>
                            <TableCell>10 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Testosterona</TableCell>
                            <TableCell>12 mg/g</TableCell>
                            <TableCell>3 mg/g</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colSpan={3}>
                                <List as='ul'>
                                    <ListItem as='li'>  BIEST (80/20) 10 mg/g = Estriol 8 mg/g + Estradiol 2 mg/g</ListItem>
                                    <ListItem as='li'>  BIEST (70/30) 6.5 mg/g = Estriol 4.55 mg/g + Estradiol 1.95 mg/g</ListItem>
                                    <ListItem as='li'>  BIEST (50/50) 4 mg/g = Estriol 2 mg/g + Estradiol 2 mg/g</ListItem>
                                </List>
                        </TableCell> 
                    </TableRow>
                    </TableBody>
                   
                    
       
                    </Table>
      
            </Container>
            )}
            <br></br>
            <Form.Checkbox
                name="concentimiento"
                label="Tengo conocimiento que las anteriores tablas indican lo que OmicronLab considera como uso seguro de las hormonas"
                onChange={(_, data) =>
                formik.setFieldValue('concentimiento', data.checked)
                }
                checked={formik.values.concentimiento}
                error={formik.errors.concentimiento}
            />
            <Container>
                <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
                    Enviar Cotización
                </Form.Button>
            </Container>
        </Form>
    )
}
