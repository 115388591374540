import React, { useState, useEffect } from "react";
import { useAuth } from "../../../hooks";
import { Button, Container, Segment } from "semantic-ui-react";
import { Image } from "semantic-ui-react";
import { image } from "../../../assets";
import { ENV } from "../../../utils";
import "./Perfil.scss";
import { User } from "../../../api/user";
import { PerfilForm } from "../../../components/Admin/Perfil/PerfilForm/PerfilForm";
import { BasicModal } from "../../../components/Shared";

const userController = new User();

export function Perfil() {
  const { accessToken, user: currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [titleModal, setTitleModal] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const onReload = () => {
    setReload((prevState) => !prevState);
  };
  const onOpenCloseModal = () => setShowModal((prev) => !prev);

  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${currentUser.email}`);
    onOpenCloseModal();
  };

  useEffect(() => {
    (async () => {
      try { 
        console.log("estoy adentro");
        console.log(currentUser._id);
        console.log(accessToken);
        const response = await userController.getMe(accessToken);
        await setUser(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reload, accessToken]);

  const imagenUrl = user && user.avatar ? `${ENV.USUSARIOS}/${user.avatar}` : image.noAvatar;

  return (
    <Container>
      <div className="perfil">
        <div className="imagen-perfil">
          <Image avatar src={imagenUrl} />
        </div>
        <div className="center">
          <div className="profile-container">
            <h1>Mi Perfil</h1>
            <h2> Nombre:{" "} {user && `${user.firstname} ${user.lastname}`}</h2>
            <h2>Email: {user && user.email}</h2>
            <h2>Rol: {user && user.role}</h2>
            <Button className="big" icon primary onClick={openUpdateUser}> Editar </Button>
          </div>
        </div>
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <PerfilForm
          close={onOpenCloseModal}
          onReload={onReload}
          user={user}
        />
      </BasicModal>
    </Container>
  );
}
