import { ENV } from "../utils";

export class Bioequal {
    baseApi = ENV.BASE_API;
    
    
    async createBioequal(accessToken, BioequalData) {
      try {
          const data = BioequalData;
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
              formData.append(key, data[key]);
          })
          const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUAL}`
          const params = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(data),
          }
          const response = await fetch(url, params)
          const result = await response.json()
          if (response.status !== 200) throw result;
          return result;
      } catch (error) {
          throw error;
      }
  }

  
  async updateBioequal(accessToken, idBioequal, BioequalData) {
    try {
        const data = BioequalData;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUAL}/${idBioequal}`
        const params = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        }
        const response = await fetch(url, params)
        const result = await response.json()
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
}
   
async getBioequalsByAsesor(asesor, params) {
    try {
        const pageFilter = `page=${params?.page || 1}`;
        const limitFilter = `limit=${params?.limit || 10}`;
        const email = `asesor=${asesor || ""}`;
        const url = `${this.baseApi}/${ENV.API_ROUTES.BIOEQUALS}?${email}&${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error
    }
  }
    

}