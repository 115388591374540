import React from 'react'
import { Container } from 'semantic-ui-react';


export function AsesorView(props) {
  const { bioequal } = props;

  return (
    <div className='cotizacion-principal'>
      <Container className='cotizacion-principal_info'>
        <p>Cotización: <span className='cotizacion-principal_info-dxp'>{bioequal.folio}</span></p>
        <p>Nombre del Cliente: <span className='cotizacion-principal_info-cliente'>{bioequal.cliente}</span></p>
        <p>Especialidad: <span className='cotizacion-principal_info-especialidad'>{bioequal.especialidad}</span></p>
        <p>Base: <span className='cotizacion-principal_info-base'>{bioequal.base}</span></p>
        <p>Activos: <span className='cotizacion-principal_info-activos'>{bioequal.activos}</span></p>
        <p>Padecimiento: <span className='cotizacion-principal_info-padecimiento'>{bioequal.padecimiento}</span></p>

      </Container>
    </div>

  )
}
