import * as Yup from "yup";

export function initialValues(equal, email, nombreCompleto) {
    return {
        cliente: equal?.cliente || "",
        asesor: equal && equal.asesor ? equal.asesor : email,
        asesornom: equal && equal.asesornom ? equal.asesornom : nombreCompleto,
        padecimiento: equal?.padecimiento || "",
        base: equal?.base || "",
        activos: equal?.activos || "",
        especialidad: equal?.especialidad || ""
    }
}

export function validationSchema() {
    return Yup.object({
        cliente: Yup.string().required(true),
        base: Yup.string().required(true),
        padecimiento: Yup.string().required(true),
        especialidad: Yup.string().required(true),
        activos: Yup.string().required(true),
        concentimiento: Yup.boolean()
        .oneOf([true], 'Debes aceptar los términos y condiciones')
        .required('Este campo es requerido'),
        });
}